@import 'styles/_variables';

.header__navigation__item-with-submenu__popover__list {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  text-align: right;

  &__item {
    width: 100%;
    border-top: 1px solid $foundation-lighter-color;
    font-size: $font-size-large;
    font-weight: 500;
    text-transform: none;

    &:first-child {
      border-top: none;
    }

    &__child {
      display: block;
      padding: 18px 21px;
      color: $header-text-color;
    }
  }
}
