/*! critical:start */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header__filter-page-navigation-tabs {
  height: 100%;
  margin-bottom: 0;
  padding-left: 20px;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
}
.header__filter-page-navigation-tabs::-webkit-scrollbar {
  display: none;
}
.header__filter-page-navigation-tabs__tab {
  display: inline-block;
  position: relative;
  font-size: 15px;
  font-weight: 500;
}
.header__filter-page-navigation-tabs__tab__text {
  display: inline-block;
  padding: 16px;
  color: black;
  cursor: pointer;
}
.header__filter-page-navigation-tabs__tab__active {
  font-weight: 600;
}
.header__filter-page-navigation-tabs__tab__active::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-radius: 10px 10px 0 0;
  background: #2e7ec6;
}
.header__filter-page-navigation-tabs__tab__disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: progress;
}
/*! critical:end */
