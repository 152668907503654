/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.menu__item-group__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-right: 30px;
  padding-left: 16px;
}
.menu__item-group__list {
  display: block;
  padding: 0;
}
.menu__item-group__list .menu__item {
  padding-left: 30px;
}
