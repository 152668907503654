/*! critical:start */
@import "styles/_variables.scss";

.header__navigation__mobile-user-menu {
  border: none;
  font-weight: 400;

  :global {
    .menu__item {
      font-size: $font-size-large;

      a {
        color: $brand-link-color;
      }
    }
  }

  &__link {
    margin-left: -$space-1;
    color: $brand-link-color;
    line-height: 53px;

    &--bold {
      font-weight: 600;
    }

    &:active,
    &:hover,
    &:focus {
      background: $white;
      color: $brand-color;
    }
  }

  &__fencing-item {
    display: inline-flex;
    justify-content: space-between;

    &--disabled {
      margin: 0;
      margin-left: -$space-1;
      color: $disabled-text-color;
    }

    &--upgrade {
      margin-left: $space-1;
      color: $business-color;
    }
  }
}

/*! critical:end */
