/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.menu__item {
  display: block;
  align-items: center;
  margin: 0;
  padding: 0 16px;
  clear: both;
  border-bottom: 1px solid #d9d9d9;
  background: #fff;
  color: #40556b;
  font-size: 13px;
  line-height: 53px;
  white-space: normal;
  cursor: pointer;
}
.menu__item:last-child {
  border-bottom: 0;
}
.menu__item a {
  display: block;
  color: #40556b;
}
