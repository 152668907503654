/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.menu {
  margin: 0;
  padding: 0;
}
.menu--vertical {
  overflow: hidden;
  list-style-type: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.menu--inline .menu__item {
  display: flex;
}
