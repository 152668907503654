/*! critical:start */
@import "styles/_variables.scss";

.header__navigation {
  display: flex;
  position: relative;
  justify-content: space-between;
  height: 74px;
  padding: $space-2;
  font-size: $font-size-medium;
  font-weight: 600;
  text-transform: uppercase;

  &__logo {
    :global {
      .icon-short-logo,
      .icon-logo {
        color: $black;
        font-size: 34px;
      }
    }
  }

  &__project-navigation {
    display: inline-flex;
    align-items: center;
  }

  &__side {
    display: flex;
    white-space: nowrap;
  }

  &__logo__image {
    max-width: 100%;
    height: 100%;
  }
}

/*! critical:end */
