/*! critical:start */
@import "styles/_variables.scss";

.header__navigation__mobile-main-menu {
  border: none;
  font-weight: 400;

  :global {
    .menu__item {
      &:last-child {
        border-bottom: 0;
      }

      a {
        color: $brand-link-color;
      }
    }

    .menu__submenu,
    .menu__item {
      padding: 0;
      border-bottom: 1px solid $border-color;
      font-size: $font-size-large;

      .menu__submenu__title {
        margin-left: -$space-1;
        color: $brand-link-color;
        line-height: 53px;
      }

      &,
      .menu__submenu__title {
        &:active,
        &:hover,
        &:focus {
          background: $white;
          color: $brand-color;
        }
      }

      &::after {
        content: none;
      }

      .menu__submenu__list--visible {
        border: none;
      }
    }
  }

  &__search {
    display: flex;
    margin-bottom: 24px;

    :global {
      .header__navigation__search__content {
        width: 100%;
      }

      .header__navigation__search__input {
        max-width: unset;

        &.ant-input-affix-wrapper,
        &.ant-input-affix-wrapper:hover {
          .ant-input {
            border-color: $border-color;
          }
        }
      }
    }
  }

  &__upgrade-item {
    :global {
      .link {
        color: $business-color;
      }
    }
  }
}

/*! critical:end */
