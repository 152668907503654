/*! critical:start */
@import "styles/_variables.scss";

.header__navigation__desktop-side-menu__upgrade {
  .link {
    color: $business-color;
  }
}

/*! critical:end */
