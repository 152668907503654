/*! critical:start */
@import 'styles/_variables';

.header__navigation__item-with-popover {
  &:hover {
    :global {
      .header__navigation__item-with-popover__popover {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  &__trigger--for-popover {
    z-index: 15;
  }

  &__popover {
    display: block;
    position: absolute;
    z-index: 14;
    top: -10px;
    flex-direction: column;
    align-items: flex-end;
    min-width: 200px;
    padding-top: $space-6;
    transform: scale(0);
    transform-origin: right top;
    transition: opacity 0.15s ease-in, transform 0.15s ease-in;
    border: $border;
    border-radius: $default-border-radius;
    opacity: 0;
    background-color: $white;
    box-shadow: 0 4px 12px $light-shadow-color;
    color: $header-text-color;

    &--right {
      left: -15px;
    }

    &--left {
      right: -15px;
    }
  }
}
