@import 'styles/_variables';

.header__navigation__item__overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: $space-3;
  overflow: scroll;
  background: $white;
  box-shadow: $default-box-shadow;
  text-transform: none;

  @media (min-width: 576px) {
    width: 50%;
  }

  &__close {
    color: $header-text-color;
    font-size: 16px;
    text-align: right;
  }

  &__content {
    margin-top: 36px;
  }
}
