@import "styles/_variables.scss";

.header__navigation__desktop-user-menu__item {
  &--disabled {
    margin-bottom: $space-0;
    color: $disabled-text-color;
    cursor: not-allowed;
  }

  &--upgrade {
    color: $business-color;
  }

  &--bold {
    font-weight: 600;
  }
}
