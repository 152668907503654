/*! critical:start */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header__navigation__item {
  display: inline-flex;
  position: relative;
  align-items: center;
  margin-left: 16px;
}
@media (max-width: 1279px) {
  .header__navigation__item {
    margin-left: 12px;
  }
}
.header__navigation__item__trigger {
  display: flex;
  z-index: 13;
  flex-wrap: nowrap;
  align-items: center;
  color: #242424;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}
@media (max-width: 1279px) {
  .header__navigation__item__trigger {
    z-index: 3;
  }
}
.header__navigation__item__trigger .header__navigation__item__trigger__label {
  z-index: 13;
  font-weight: 600;
}
.header__navigation__item__trigger:focus,
.header__navigation__item__trigger:hover {
  color: #242424;
}
.header__navigation__item__trigger .icon {
  z-index: 13;
  width: 20px;
  margin-right: 8px;
  font-size: 20px;
}
@media (max-width: 1279px) {
  .header__navigation__item__trigger .icon {
    margin-right: 0;
  }
}
/*! critical:end */
